@media (min-width:768px){
    .profilo-input.profilo-termini .input-data label.error,
    .profilo-input.profilo-privacy .input-data label.error{
        margin-left: 30px;
        margin-top: -7px;
    }

    .landing.utente-registrazione .cont-landing .registration-form.grey-form .block-link .col-sm-4:last-child{
        width: 100% !important;
    }
    .landing.utente-attivazione .text-landing-row .block-link .col-sm-4{
        width:50% !important;
    }
}