.utente-login{
  .login-form form input[type="email"], .login-form form input[type="text"], .login-form form input[type="password"] {
    text-align: center; }
  .forgot-link {
    text-align: right;
    margin-bottom: 20px; }
  .forgot-link a {
    font-weight: 100;
    font-size: 13px;
    color: $primary; }

  .profilo-registrati-a{
    padding-top: 20px;
  }
  .profilo-registrati-a, .profilo-password-dimencaticata-a{
    text-align: left;
  }
  .profilo-registrati-a a, .profilo-password-dimencaticata-a a{
    color:$basic;
  }
  .recupero{
    max-width: 400px;
    margin:0 auto;
    width:100%
  }
  .text-landing-row{
    .error{
      display: block;
    }
  }
  .message{
    p{
      text-align: center;
      font-weight: bold;
      font-size: 17px;
      margin-bottom: 15px;
    }
  }
}