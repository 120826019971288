//attuale style

/* [+] col-xxs- */
.col-xxs-1, .col-xxs-2, .col-xxs-3, .col-xxs-4, .col-xxs-5, .col-xxs-6, .col-xxs-7, .col-xxs-8, .col-xxs-9, .col-xxs-10, .col-xxs-11, .col-xxs-12 {
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
}

@media (max-width: 479px) {
    .col-xxs-1, .col-xxs-2, .col-xxs-3, .col-xxs-4, .col-xxs-5, .col-xxs-6, .col-xxs-7, .col-xxs-8, .col-xxs-9, .col-xxs-10, .col-xxs-11, .col-xxs-12 {
        float: left;
    }

    .col-xxs-12 {
        width: 100%;
    }

    .col-xxs-11 {
        width: 91.66666667%;
    }

    .col-xxs-10 {
        width: 83.33333333%;
    }

    .col-xxs-9 {
        width: 75%;
    }

    .col-xxs-8 {
        width: 66.66666667%;
    }

    .col-xxs-7 {
        width: 58.33333333%;
    }

    .col-xxs-6 {
        width: 50%;
    }

    .col-xxs-5 {
        width: 41.66666667%;
    }

    .col-xxs-4 {
        width: 33.33333333%;
    }

    .col-xxs-3 {
        width: 25%;
    }

    .col-xxs-2 {
        width: 16.66666667%;
    }

    .col-xxs-1 {
        width: 8.33333333%;
    }

    .col-xxs-pull-12 {
        right: 100%;
    }

    .col-xxs-pull-11 {
        right: 91.66666667%;
    }

    .col-xxs-pull-10 {
        right: 83.33333333%;
    }

    .col-xxs-pull-9 {
        right: 75%;
    }

    .col-xxs-pull-8 {
        right: 66.66666667%;
    }

    .col-xxs-pull-7 {
        right: 58.33333333%;
    }

    .col-xxs-pull-6 {
        right: 50%;
    }

    .col-xxs-pull-5 {
        right: 41.66666667%;
    }

    .col-xxs-pull-4 {
        right: 33.33333333%;
    }

    .col-xxs-pull-3 {
        right: 25%;
    }

    .col-xxs-pull-2 {
        right: 16.66666667%;
    }

    .col-xxs-pull-1 {
        right: 8.33333333%;
    }

    .col-xxs-pull-0 {
        right: auto;
    }

    .col-xxs-push-12 {
        left: 100%;
    }

    .col-xxs-push-11 {
        left: 91.66666667%;
    }

    .col-xxs-push-10 {
        left: 83.33333333%;
    }

    .col-xxs-push-9 {
        left: 75%;
    }

    .col-xxs-push-8 {
        left: 66.66666667%;
    }

    .col-xxs-push-7 {
        left: 58.33333333%;
    }

    .col-xxs-push-6 {
        left: 50%;
    }

    .col-xxs-push-5 {
        left: 41.66666667%;
    }

    .col-xxs-push-4 {
        left: 33.33333333%;
    }

    .col-xxs-push-3 {
        left: 25%;
    }

    .col-xxs-push-2 {
        left: 16.66666667%;
    }

    .col-xxs-push-1 {
        left: 8.33333333%;
    }

    .col-xxs-push-0 {
        left: auto;
    }

    .col-xxs-offset-12 {
        margin-left: 100%;
    }

    .col-xxs-offset-11 {
        margin-left: 91.66666667%;
    }

    .col-xxs-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-xxs-offset-9 {
        margin-left: 75%;
    }

    .col-xxs-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-xxs-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-xxs-offset-6 {
        margin-left: 50%;
    }

    .col-xxs-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-xxs-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-xxs-offset-3 {
        margin-left: 25%;
    }

    .col-xxs-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-xxs-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-xxs-offset-0 {
        margin-left: 0;
    }
}

/* [+] hidden-xxs */
@media (max-width: 479px) {
    .hidden-xxs {
        display: none !important;
    }
}

/* [+] visible-xxs and visible-xxs- */
.visible-xxs {
    display: none !important;
}

.visible-xxs-block, .visible-xxs-inline, .visible-xxs-inline-block {
    display: none !important;
}

@media (max-width: 479px) {
    .visible-xxs {
        display: block !important;
    }

    table.visible-xxs {
        display: table;
    }

    tr.visible-xxs {
        display: table-row !important;
    }

    th.visible-xxs, td.visible-xxs {
        display: table-cell !important;
    }

    .visible-xxs-block {
        display: block !important;
    }

    .visible-xxs-inline {
        display: inline !important;
    }

    .visible-xxs-inline-block {
        display: inline-block !important;
    }
}


/* NUOVO DO DIGITALE /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
.brd-box-nuovo-do, .no-brd-box-nuovo-do {
    padding: 40px 10px 0px 10px;
    margin: 0px 0 0px 0;
}

.brd-box-nuovo-do img, .no-brd-box-nuovo-do img {
    max-width: 80px;
}

.brd-box-nuovo-do p, .no-brd-box-nuovo-do p {
    min-height: 100px;
    line-height: normal;
}

.brd-box-nuovo-do {
    border-left: 0px solid #000000;
}

.content-ico-nuovo-do-dig {
    padding: 0px;
}

.ico-nuovo-do-dig-left, .ico-nuovo-do-dig-center, .ico-nuovo-do-dig-right {
    padding-top: 0px!important;
    padding-bottom: 30px!important;
}

.ico-nuovo-do-dig-left img, .ico-nuovo-do-dig-center img, .ico-nuovo-do-dig-right img {
    max-width: 307px!important;
}

@media all and (min-width: 992px) {
    .brd-box-nuovo-do, .no-brd-box-nuovo-do {
        padding:40px 10px 0px 10px;
        margin: 40px 0 40px 0;
    }

    .brd-box-nuovo-do img, .no-brd-box-nuovo-do img {
        max-width: 60px;
    }

    .brd-box-nuovo-do p, .no-brd-box-nuovo-do p {
        min-height: 120px;
        line-height: normal;
        padding: 0px;
    }

    .brd-box-nuovo-do {
        border-left: 1px solid $max-dark;
    }

    .content-ico-nuovo-do-dig {
        width: 939px;
        margin-left: -15px;
        padding: 0px;
    }

    .ico-nuovo-do-dig-left, .ico-nuovo-do-dig-center, .ico-nuovo-do-dig-right {
        padding-right: 0px!important;
        padding-left: 0px!important;
        padding-bottom: 0px!important;
    }

    .ico-nuovo-do-dig-left {
        padding-right: 6px!important;
        padding-left: 0px!important;
    }

    .ico-nuovo-do-dig-center {
        padding-right: 3px!important;
        padding-left: 3px!important;
    }

    .ico-nuovo-do-dig-right {
        padding-right: 0px!important;
        padding-left: 6px!important;
    }
}

//sf style
*:active, *:focus, a:active, button:active, .btn:active{
    outline:0 none;
    box-shadow: none;
}
.padd{
    img{
        max-width: 100%;
    }
    figure.head-shop{
        margin-bottom: 30px;
        img{
            max-width: 865px;
            width: 100%;
            margin: 0 auto;
            display: block;
        }
    }
    .brd-box-nuovo-do, .no-brd-box-nuovo-do {
        img {
            max-width: 80px;
        }
         p {
            padding: 20px 10px 0;
            min-height: 100px;
            line-height: normal;
            color: $light-text;
            font-family: $font-secondary;
            font-weight: 400;
            font-size: 12px;
         }
    }
}
.checkout-wizard{
    form{
    max-width: 1000px;
    }
    .offers-shop{
        display: flex;
        justify-content: center;
        @media #{$xs}{
            display: block;
        }
        .box-offer{
            padding: 10px;
            text-align: center;
            .box{
                border:2px solid $grey;
                border-radius: 15px;
                padding: 20px;
                text-align: center;
                margin-bottom: 20px;
                position: relative;
                &.on{
                    border:2px solid $primary;
                }
                a.info-tooltip{
                    position: absolute;
                    left: 10px;
                    top: 2px;
                    color: $primary;
                    width: 19px;
                    height: 19px;
                    border: 2px solid $primary;
                    border-radius: 16px;
                    text-align: center;
                    font-size: 14px;
                    line-height: 15px;
                    font-weight: 700;
                    color: $primary;
                    i{
                        font-style: normal!important;
                    }
                }
                h4{
                    color:$primary;
                    font-weight: bold;
                    font-size: 28px;
                }
                p{
                    font-weight: 700;
                    font-size: 14px;
                    min-height: 70px;
                    color:$max-dark;
                    font-family:$font-secondary;
                }
                .price{
                    font-family: $font-secondary;
                    color: #000;
                    font-size: 20px;
                    font-weight: 800;
                }
            }
            label {
                display: block;
                max-width: 100%;
                color: #545856;
                margin-bottom: 5px;
                font-weight: 300;
                text-align: center;
            }
        }
    }
    .buttons-shop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width:600px){
            display: block;
            text-align: center;
        }
        a{
            @media (max-width:600px){
                display: block;
                margin:10px auto !important ;
            }
        }
    }
}
a.profilo-btn.gray-btn, button.profilo-btn.gray-btn{
    background-color: $grey!important;
    border-color: $grey!important;
}

//modals
.modal{
    &.licenza-modal, &.info-modal{
        width: 640px;
        max-width: 100%;
        margin: 0 auto;
        .modal-body{
            position: relative;
        }
        .close {
            width: 25px;
            height: 25px;
            background: $grey;
            border-radius: 25px;
            right: 10px;
            top: 10px;
            position: absolute;
            opacity: 1;
            color: #fff;
            text-shadow: none;
            &, &:afetr, &:before, *{
                color:#fff;
                text-shadow:none;
            }
        }
        .modal-dialog{
            top: 25vh;
            @media #{$xs}{
                top: 10vh;
            }
            margin: 0 auto;
            width: 90%;
            .modal-content{
                border-radius: 20px;
            }
            h2{
                color:$primary;
                font-size: 24px;
                text-transform: none;
            }
            p {
                font-size: 14px;
            }
            .buttons-shop{
                padding-top: 30px;
            }
        }
        .checkout-wizard .buttons-shop .btn{
            width: 70px;
            font-size: 16px;
            border-radius: 18px;
        }
    }
    &.info-modal{
        text-align: center;
        width: 400px;
    }
}

.modal-content{
    .modal-body{
        .close{
            span{
                top: 2px;
                position: absolute;
                left: 0;
                right: 0;
            }
        }
    }
}
.checkout-wizard .buttons-shop .gray-btn {
    background-color: #9B9B9B!important;
    border: 2px solid #9b9b9b!important;
}