.landing{
    background: $basic;
    .cont-landing{
        background-color:$beige;
        padding: 0 0 50px 0;
        margin-top: -30px;
        color: $primary;
        margin-bottom: 20px;
        color:$basic;
        .block-link{
            text-align: center;
            padding: 20px 0;
            text-transform: uppercase;
            font-size: 23px;
            
            a.buttons{
                background-color: $primary;
                padding: 10px 20px;
                color: #FFF;
                font-weight: 100;
                text-transform: none;
                border: 1px solid $primary;
                border-radius: 10px;
                display: block;
                max-width: 235px;
                margin: 0 auto;
                font-size: 21px;
                &:hover{
                    background-color: $basic;
                    color: $primary;
                }
                @media #{$xs}{
                    margin-bottom: 20px;
                }
            }
        }
        h3.inev{
            text-align: center;
            text-transform: uppercase;
            font-size: 17px;
            font-weight: 600;
            margin-bottom: 50px;
        }
    }
    .landing-heading{
        background: $primary;
        padding: 10px 30px 50px 30px;
        text-align: center;
    }
    .text-landing-row{
        color: $secondary;
        margin: -35px 40px 30px;
        background: #FFF;
        padding: 30px 15px 15px;
        border-radius: 10px;
        @media (max-width:768px){
            margin: -35px 15px 30px;
        }
    }
    padd{
        padding: 10px 40px;
    }
    p{
        color:$secondary;
        font-size: 13px;
        line-height: 1.8;
    }

    .apps-link{
        padding-top: 20px;
        text-align: center;
        h4{
            color:$secondary;
        }
        a{
            margin: 0 10px;
            display: inline-block;
            &:hover{
                opacity: 0.8;
            }
            img{
                max-width: 180px;
            }
        }
    }

}
