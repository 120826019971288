.completa-i-dati, .attivazione-completata{
    font-family:$font-secondary;
    color:$max-dark;
        .cont-contratto{
            padding: 20px 30px;
            @media #{$xs}{
                padding: 10px;
            }
            label.error{
                display: inline-block;
                margin: 0 5px 10px;
            }
            .row{
                margin-bottom: 30px;
                input[type=checkbox], input[type=radio] {
                    margin: 4px 10px 0 0;
                    display: inline-block;
                    vertical-align: top;
                    @media #{$xs}{
                        margin: 4px 10px 0 5px;
                    }
                }
            }
            .fatturazione-checkbox-text{
                ul{
                    padding-left: 0;
                }
            }
            .fatturazione-checkbox-text, .fatturazione-clause-checkbox{
                display: inline-block;
                width: 95%;
                vertical-align: top;
                @media #{$xs}{
                    width: 85%;
                }
            }
            a,a:hover,a:active{
                color:$primary;
            }
        }
        p{
            font-size: 14px;
            line-height: 1.8;
            margin: 0!important;
            padding: 0!important;
        }
    b{
       text-transform: uppercase;
        font-weight: 600;
        margin: 0 5px; 
    }
    .form-fatturazione-container{
        max-width: 851px;
        margin: 0 auto;
        .payment-method{
            background-color: #efefef;
            border-radius: 15px;
            padding: 11px 20px 10px;
            margin: 5px;
            img{
                max-width: 100%;
            }
        }
        form{
            width: 100%;
            margin: 15px auto 30px auto;
            border-top: 0 none;
            padding: 15px 0 30px 0;
            max-width: 1140px;
            .fatturazione-container{
                background-color: #EFEFEF;
                border-radius: 15px;
                padding: 20px;
                select {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    border-radius: 9px;
                    background: url(../images/freccetta.png) right 10px center no-repeat #FFF;
                    text-indent: 10px;
                    border: 1px solid #666;
                    max-width: 100%;
                    height: 45px;
                    width: 100%;
                    margin-bottom: 20px;
                }
                input[type="email"], input[type="text"], input[type="password"] {
                    border: 0 none;
                    display: block;
                    width: 100%;
                    height: 45px;
                    line-height: 40px;
                    background: $basic;
                    margin-bottom: 20px;
                    text-align: left;
                    font-weight: 100;
                    border-radius: 10px;
                    padding-left: 10px;
                    border: 1px solid #666;
                    outline: 0 none;
                    color: #9B9B9B;
                    font-size: 16px;
                    
                }
            }
        }
    }
}
.attivazione-completata{
    p{
        margin-bottom: 20px!important;
    }
}
.payment-method input[type=radio] {
    margin-right: 5px;
    margin-top: -2px;
}
#activate-license-block{
   input[type="checkbox"]{
        margin-top: 5px;
        position: relative;
        z-index: 999;
   } 
}

/* checkbox fatturazione */
.fatturazione-checkbox-text{
    position: relative;
    padding-right: 30px;
    padding-left: 20px;
    
    .fatturazione-checkbox-info{
        position: absolute;
        right: 0;
        top: -3px;
    }
}