.modal{
    .modal-dialog{
        width: 80%;
    }
 .modal-header{
   background: $primary;
   h4{
     color:$basic;
   }
   .close{
      font-size: 28px;
      color:$basic;
      opacity: 1;
      font-weight: normal;
   }
 } 
  .modal-body{
    overflow-y: auto;
    overflow-x: hidden;
    line-height: 23px;
    font-size: 13px;
  }
}


.licenza-modal{
    .modal-body{
        a{
            color:$primary;
        }
    }
    p{
        line-height: 1.8;
    }
    p b{
        text-transform: none;
        font-weight: 600;
        line-height: 1.8;
    }
}