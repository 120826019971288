body {
  color: $secondary;
  background: $basic;
  font-family: $font-primary; }
@media (min-width: 950px){
  .container-devoto {
      width: 920px;
  }
}
hr{
  margin-top: 30px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid $primary;
  max-width: 1100px;
  text-align: center;
}

a, a:hover, a:focus, a:active {
  text-decoration: none; }

a:focus {
  outline: 0 none;
  outline-offset: 0;
}

h1 {
  color: $basic; }

h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 700;
}

h6 {
  text-align: left;
  font-size: 15px;
  font-weight: bold;
}

h3{
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
}

p{
  padding: 0 15px;
  margin-bottom: 10px; }

.absolute-form {
    padding: 5px 15px 15px 15px;
    border-radius: 0 0 10px 10px;
    background: $basic;
    margin-top: 0;
    h2{
      color:$secondary;
    }
}

label {
  display: block;
  max-width: 100%;
  color:$secondary;
  margin-bottom: 5px;
  font-weight: 700;
  text-align: left;
  &.error {
    font-weight: normal;
    color: $primary;
    font-size: 13px;
  }
}

form {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}


a.btn , .profilo-btn,.profilo-btn:hover,.profilo-btn:focus,.profilo-btn:active {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    border: 2px solid $primary !important;
    color: #FFF;
    margin-bottom: 10px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    background: $primary !important;
    border-radius: 10px;
    padding: 7px 0;
  
  &:hover{
    background:$basic !important;
    color:$primary;
  }
}

span.black{
    color:$secondary;
}
span.red{
    color:$primary;
}


form input[type="email"], form input[type="text"], form input[type="password"] {
  border: 0 none;
  display: block;
  width: 100%;
  height: 45px;
  line-height: 40px;
  background: $light-grey;
  margin-bottom: 10px;
  text-align: left;
  font-weight: 100;
  color: $secondary;
  border-radius: 10px;
  padding-left: 10px;



}
form input[type="submit"] {
  margin-top: 20px;
  border: 1px solid $primary;
  display: block;
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  height: 45px;
  line-height: 40px;
  background: $primary;
  margin-bottom: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: $basic;
  border-radius: 10px;
  &:hover{
    color:$basic;
    background:$primary;
  }
}


.inev{
  &.sottotitolo{
    margin-bottom: 25px;
  }
}
.error{
  margin-bottom: 15px;
  text-align: left;
}

#cart-product-description-1{
  li{
    text-align: left;
  }
}

h5.article-select{
  color:$primary;
  font-size: 21px;
  margin-top: 0;
}