.checkout-wizard{
    font-family: $font-secondary;
    .buttons-shop{
        .btn{
            display: block;
            max-width: 145px;
            color: $basic;
            margin-bottom: 10px;
            text-align: center;
            font-size: 24px;
            font-weight: 600;
            border-radius: 10px;
            padding: 7px 20px;
            margin: 0;
            font-family:$font-secondary;
            letter-spacing: 1px;
            height: 50px;
            @media #{$xs}{
                font-size: 20px;
                padding: 5px 10px;
                margin: 0 3px;
            }
        }
        .gray-btn{
            background-color: #9B9B9B;
        }
        .profilo-btn{
            border: 2px solid $primary !important;
            background: $primary !important;
        }
    }
}

 .summary-shop{
    border-top: 2px solid #9B9B9B;
    padding: 30px 0;
    margin: 30px 0;
    .checkout{
        input[type="text"]{
            background-color: #fff;
            font-size: 17px;
        }
    }
    p{
        color: $max-dark;
        font-size: 18px;
        line-height: 130%;
        padding: 0;
        margin-bottom: 0;
    }
    a.info-tooltip {
        color: $primary;
        width: 19px;
        height: 19px;
        border: 2px solid $primary;
        border-radius: 16px;
        text-align: center;
        font-size: 14px;
        line-height: 15px;
        display: inline-block;
        margin: 5px;
        @media #{$phone}{
            display: inline-block;
            margin: 5px;
        }
    }
    table.checkout{
        width: 100%;
        tr{
            td{
                text-align:left;
                vertical-align: top;
                @media #{$phone}{
                    text-align: center;
                    display: block;
                    width: 100%;
                    margin: 30px auto 0;
                }
            }
            td+td{
                text-align: right;
                @media #{$phone}{
                    text-align: center;
                    display: block;
                    margin: 20px auto;
                }
                input[type="number"]{
                    font-family:$font-secondary;
                    font-size: 18px;
                    text-indent: 10px;
                    width: 66px;
                    height: 30px;
                    border: 1px solid $max-dark;
                }
                span.total-price {
                    display: block;
                    font-size: 32px;
                    font-weight: 800;
                    margin: 20px 0;
                    color: $max-dark;
                    @media #{$xs}{
                        font-size: 20px;
                    }
                }
                label{
                    display: inline-block;
                    margin-right: 15px;
                    text-align: left;
                    font-size: 18px;
                    font-weight: 100;
                }
                textarea:focus, input:focus{
                    outline: none;
                }
            }
        }
    }
    form input[type="text"]{
        font-size: 17px;
    }
}

a.info-tooltip {
    color: $primary;
    width: 19px;
    height: 19px;
    border: 2px solid $primary;
    border-radius: 16px;
    text-align: center;
    font-size: 14px;
    line-height: 15px;
    display: inline-block;
    margin: 5px;
    @media #{$phone}{
        display: inline-block;
        margin: 5px;
    }
}

.payment-method{
    .xcomponent-outlet{
        min-height:30px!important;
        height:30px!important;
    }
}

.row.payment-method {
    background: #EFEFEF;
    margin: 5px;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    justify-content: baseline;
    label{
        display: inline-block;
        margin: 0 5px;
    }
}

.payment-method-paypall{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.fatturazione-container{
    a.info-tooltip{
        display: inline-block;
        margin: 5px;
    }
}