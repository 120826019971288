//font
@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i,900,900i|Noto+Sans:400,400i,700,700i');
//media
$sm: "only screen and (max-width:991px)";
$xs: "only screen and (max-width:767px)";
$phone: "only screen and (max-width:420px)";


//colors
$primary: #cb0435;
$secondary: #545856;
$basic: #FFF;
$grey: #bcbdc0;
$light-grey: #EFEFEF;
$bgGrey: #E6E7E8;
$textGrey: #B2B2B2;
$linkColor: #cb0435;
$lemma-base:#cb0435;
$beige:#EFEFEF;
$max-dark:#000;
$light-text:#777777;
//font family
$font-primary: 'Noto Sans', 'Arial';
$font-secondary: 'Lato';


