.utente-registrazione {

	label div{
		text-indent: 0!important;
	}
    .registration-form{
       form{
        max-width: 900px;
        width: 100%;
        margin: 0 auto;
        background: $basic;
        padding: 30px;
        border-radius: 15px;
        border-radius: 0 0 15px 15px;
        color:$secondary;
        &.profilo-login{
            max-width: 400px;
            label{
                display: block;
                text-align: left;
            }
        } 
       }
       h1{
           font-size: 18px;
       }
       .sottotitolo {
            max-width: 900px;
            margin: 0 auto;
            padding-left: 0;
        }
        h2 {
            margin-bottom: 20px;
        }
        .col-sm-6 {
            margin-bottom: 10px;
            text-align: left;
            padding-left: 0;
            padding-right: 0;
        }

        @media (max-width: 900px) {
            padding: 0 10px;
        }
    }
    .confirmation-page img {
        max-width: 70px;
    }

    textarea:focus, input:focus {
        outline: none;
        box-shadow: 0 0px 10px #CCC; }

    .profilo-termini{
        padding-top: 20px;
    }
    .profilo-termini,.profilo-privacy, .profilo-marketing, .profilo-marketingTerzi, .profilo-profilazione{
        font-weight: normal;
        clear: both;
        .preexplain{
            padding: 0;
            float:left;
            width: 85%;
            color:#000;
            text-align: left;
            @media(max-width:767px){
                width: 100%;
                float: none;
                clear: both;
            }
            p{
                text-align: left;
                padding: 0;
            }
        }
        .input-data{
            float: left;
            width: 15%;
            @media(max-width:767px){
                width: 100%;
                float: none;
                clear: both;
            }
        }
    }
    .profilo-privacy {
        font-weight: normal;
        font-size: 14px;
        color: $basic;
        text-align: left;
        float: none;
        clear: both;

        input[type="checkbox"], .explain {
            display: inline-block;
            vertical-align: middle;
            color: $basic;
        }
    }
    .profilo-privacy input[type="checkbox"] p, .profilo-privacy .explain p {
        color: $basic; }
    .profilo-privacy input[type="checkbox"] {
        margin-top: -10px;
        margin-right: 5px;
        display: inline-block;}


	.profilo-professione{
 		float: none;
        clear: both;
	}
    .profilo-marketing, .profilo-marketingTerzi, .profilo-profilazione {
        font-weight: normal;
        font-size: 14px;
        color: $basic;
        text-align: left;
        float: none;
        clear: both;
        padding: 0;
        .preexplain{
            padding: 0;
            p{
                padding: 0;
                
            }
        }
    }
    .profilo-marketing ul, .profilo-marketingTerzi ul, .profilo-profilazione ul {
        padding-left: 0;
        list-style: none; }
    .profilo-marketing ul li, .profilo-marketingTerzi ul li, .profilo-profilazione ul li {
        list-style: none;
        display: inline-block;
        margin-right: 10px; }
    .profilo-marketing ul li label, .profilo-marketingTerzi ul li label, .profilo-profilazione ul li label {
        display: inline-block; }
    .profilo-marketing label, .profilo-marketingTerzi label, .profilo-profilazione label {
        margin-right: 10px;
        display: block;
        text-align: left; }
    .profilo-marketing input[type="radio"], .profilo-marketingTerzi input[type="radio"], .profilo-profilazione input[type="radio"] {
        margin-right: 5px;
        display: inline-block; }
    .profilo-input{
        padding-left: 15px !important;
        padding-right: 15px !important;
        &.profilo-marketing_indiretto, &.profilo-profilazione_gdpr{
            margin-bottom: 20px;
            ul,li{
                margin: 0;
                padding: 0;
                list-style:none;
            }
            li{
                display: inline-block;
                &>div, label{
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
        &.profilo-termini, &.profilo-privacy{
            .preexplain{
                float: right !important;
                width: calc(100% - 30px);
            }
            .input-data{
                width: 30px !important;
            }
            
        }
        &.profilo-termini{
            label.error{
                margin-top: 20px !important;
                min-width: 250px !important;
            }           
        }
        &.profilo-privacy{
            label.error{
                margin-top: 45px !important;
                min-width: 250px !important;
            }
        }
        
        &.profilo-privacy{
            label.error{
                margin-top: 45px !important;
                min-width: 250px !important;
            }
        }
        &.profilo-marketing_indiretto, &.profilo-profilazione_gdpr{
            label.error{
                margin-top: 25px !important;
                min-width: 250px !important;
                
            }
        }
    }

    .profilo-box-registrazione .row:last-child .profilo-nome,
    .profilo-box-registrazione .row:last-child .profilo-cognome{
        @media (min-width:768px){
            float: left;
            width: 50%;
        }
    }
    
    .preexplain {
        padding: 0 15px;
        color: $secondary;
        margin-bottom: 10px;
        a{
            color: $primary;
        }
        p{
            color:$secondary;
        }
    }

    form{
        select {
            border: 0 none;
            display: block;
            width: 100%;
            height: 45px;
            line-height: 40px;
            border: 0 none;
            margin-bottom: 10px;
            text-align: center;
            font-weight: 600;
            color: #000;
            border-radius: 0;
            padding-left: 10px;
            color: $secondary;
            -webkit-appearance: none;
            -moz-appearance: none;
            font-weight: 100;
            appearance: none;
            background: url("../images/arrow-select.jpg") no-repeat right center $light-grey; border-radius:6px;
            option {
                text-align: center;
            }
        }
        .acceptation-box {
            padding: 5px 0 15px 0;
            text-align: left;
            label {
                font-weight: normal;
                font-size: 14px;
                text-align: left;
                a {
                    font-weight: bold;
                    color: $basic;
                }
                input[type="checkbox"] {
                    margin-right: 5px;
                    display: inline-block;
                }
            }
            .double-acceptation{
                label {
                    margin-right: 10px;
                    display: inline-block;
                    text-align: left;
                }
                input[type="radio"] {
                    margin-right: 10px;
                    display: inline-block;
                }
            }
        }
    }
    .grey-form{
        padding: 0 30px 0;
        margin-bottom: 40px;
        @media (max-width:768px){
            padding: 0;
        }
    }
}
