header {
    padding-top: 15px;
    text-align: center;
    background: $basic;
    padding-bottom: 40px;
    border-top:10px solid $primary;
    padding: 15px;
}
header h1 {
  margin-top: 5px;
  font-weight: 600;
  font-size: 28px;
  text-transform: uppercase;
}
header .do-logo {
  padding-top: 10px;
  margin-bottom: 40px;
  width: 100%;
  max-width: 330px;
}

.user-container{
  position: relative;
  max-width: 1140px;
}
.user-menu{
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
  @media (max-width:767px){
    position: relative;
    top:auto;
  }
  a{
    color:$primary;
    text-transform: uppercase;
    font-style: italic;
    text-decoration: underline;
  }
}