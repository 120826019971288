.utente-attivazione{
  .text-landing-row{
    img{
      display: block;
      margin:0 auto;
    }
  }
  a.btn, .profilo-btn.profilo-btn:focus, .profilo-btn:active {
    max-width: 420px;
    border: 2px solid $primary !important;
    color: #FFF;
    white-space: initial;
    margin-top: 20px;
    margin-bottom: 20px;
    &:hover{
      background-color: transparent;
      color: $primary;
    }
  }
}