@import "abstracts/variables";
@import "base/base.scss";

@import "components/checkout.scss";
@import "components/completa-dati.scss";
@import "components/footer.scss";
@import "components/header.scss";
@import "components/modals.scss";

@import "pages/landing.scss";
@import "pages/login.scss";
@import "pages/registrazione-conferma.scss";
@import "pages/registrazione.scss";
@import "pages/riscatto.scss";
@import "pages/shop.scss";
@import "pages/utente-recupero.scss";

/* No files to import found in plugins//* */
@import "z-fix/zzz.scss";
