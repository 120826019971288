.utente-riscatto{
  img{
    display: block;
    margin: 0 auto;
  }
  form {
    max-width: 100%;
    .form-group{
      display: inline-block;
      width: 50%;
    }
    button[type="submit"]{
      display: inline-block;
      @media(min-width:992px){
        margin-left: 30px;
      }
    }
  }
  
}

